<!-- desktop layout -->
<div class="desktop">
  <nav>
    <div class="item home">
      <a routerLink="/">
        <div class="logo">tabius</div>
        <div class="logo2">{{ i18n.logo2 }}</div>
      </a>
    </div>
    <div class="item catalog" [class.active]="activeSection === NavSection.Catalog">
      <a routerLink="{{catalogLink}}" (click)="resetCollectionPageScroll()">
        <div class="indicator-block"></div>
        <gt-svg-icon icon="artist"></gt-svg-icon>
        <div class="title">{{ i18n.catalog }}</div>
      </a>
    </div>
    <div class="item scene" [class.active]="activeSection === NavSection.Scene">
      <a routerLink="{{sceneLink}}">
        <div class="indicator-block"></div>
        <gt-svg-icon icon="scene"></gt-svg-icon>
        <div class="title">{{ i18n.scene }}</div>
      </a>
    </div>
    <div class="item studio" [class.active]="activeSection=== NavSection.Studio">
      <a routerLink="{{studioLink}}">
        <div class="indicator-block"></div>
        <gt-svg-icon icon="studio"></gt-svg-icon>
        <div class="title">{{ i18n.studio }}</div>
      </a>
    </div>
    <div class="item tuner" [class.active]="activeSection === NavSection.Tuner">
      <a routerLink="{{tunerLink}}">
        <div class="indicator-block"></div>
        <gt-svg-icon icon="tuner"></gt-svg-icon>
        <div class="title">{{ i18n.tuner }}</div>
      </a>
    </div>
    <div class="item settings" [class.active]="activeSection === NavSection.Settings">
      <a routerLink="{{settingsLink}}">
        @if (user) {
          <div class="user-icon-desktop"
               title="{{i18n.userIconTitle(user.email)}}"
               (click)="showUserInfo()">
            {{ getUserIconText() }}
          </div>
        } @else {
          <div class="login-icon-desktop" [title]="I18N.signinSignoutButton.signIn" (click)="authService.login()">
            <gt-svg-icon icon="login"></gt-svg-icon>
          </div>
        }
        <div class="indicator-block"></div>
        <gt-svg-icon icon="settings"></gt-svg-icon>
        <div class="title">{{ i18n.settings }}</div>
      </a>
    </div>
  </nav>
  <div class="sub-nav">
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item history">
      <a href="#" class="btn btn-flat-white" #showHistoryButton
         [attr.title]="i18n.historyButtonTitle"
         (click)="onShowHistoryClicked($event)"
      >
        ☰ {{ i18n.historyButton }}
      </a>
    </div>
  </div>
</div>

<!-- mobile layout (see footer component for the global menu popover.) -->
<div class="mobile">
  <nav>
    <div class="item logo">
      <a routerLink="/">
        <div class="logo">tabius</div>
      </a>
    </div>

    <div class="spacer"></div>

    @if (noSleepMode$|async) {
      <div class="item">
        <a (click)="toggleNoSleep()">
          <gt-svg-icon icon="no-sleep" class="no-sleep-icon"></gt-svg-icon>
        </a>
      </div>
    }

    @if (contextMenuAction) {
      <div (click)="activateContextMenuAction()" class="item">
        <gt-svg-icon [icon]="contextMenuAction.icon"></gt-svg-icon>
      </div>
    }

    @if (user) {
      <div class="item" (click)="showUserInfo()">
        <div class="user-icon-mobile">{{ getUserIconText() }}</div>
      </div>
    } @else {
      <div class="item" (click)="authService.login()">
        <gt-svg-icon icon="login" class="login-icon"></gt-svg-icon>
      </div>
    }
  </nav>
</div>
