<div class="content">
  <div class="head">
    <h1>tabius</h1>
    <h2>{{ i18n.titleLevel2 }}</h2>
  </div>

  <div class="buttons-row">
    <a href="{{sceneLink}}" class="btn" style="color: #8d7244">{{ i18n.sceneLink }}</a>
    <a routerLink="{{catalogLink}}" class="btn" style="color: #1c889b">{{ i18n.catalogLink }}</a>
    <a routerLink="{{studioLink}}" class="btn" style="color: #28816a">{{ i18n.studioLink }}</a>
  </div>

  <div class="feature-list">
    <h3>{{ i18n.interestingFacts }}</h3>
    <ul>
      <li>{{ i18n.worksWithNoInternetFact }}</li>
      <li>{{ i18n.noAdsFact }}</li>
      <li>
        {{ i18n.openSourceFact.tabiusSourceCode }}
        <a href="https://github.com/tabius/tabius" target="_blank" rel="noopener">
          {{ i18n.openSourceFact.isOpenOnGithub }}
        </a>.
        {{ i18n.openSourceFact.useAndImprove }}
      </li>
      <li>{{ i18n.forMusicLoversFact }}</li>
    </ul>
  </div>

  <div class="bottom-sign-in-block">
    <gt-signin-signout-button></gt-signin-signout-button>
  </div>
</div>
