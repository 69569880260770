<div class="content">

  <div *ngIf="!loaded && (isLoadingIndicatorVisible$|async)" class="indicator">
    <div class="message">{{ i18n.loadingMessage }}</div>
    <gt-loading-indicator></gt-loading-indicator>
  </div>

  @if (loaded) {
    <div class="title">
      {{ i18n.title }}
    </div>
    <gt-song [songId]="songId" [showCollectionLink]="true"></gt-song>
    <div class="user-controls">
      <gt-add-song-to-collection *ngIf="isBrowser" [songId]="songId"></gt-add-song-to-collection>
    </div>
  }
</div>
