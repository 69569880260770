<div class="content">
  <p>{{ i18n.youAreNotModerator }}</p>

  <p class="but-block">{{ i18n.but }}</p>

  <p>
    {{ i18n.butLine1 }}
    <br>
    {{ i18n.butLine2 }} <a href="{{telegramLink}}" target="_blank" rel="noopener">{{ i18n.butTelegram }}</a>
  </p>

  <p class="also-block">{{ i18n.also }}</p>

  <p>
    {{ i18n.alsoLine1 }}
    <br>
    {{ i18n.alsoLine2 }} <a routerLink="{{studioLink}}">{{ i18n.alsoStudio }}</a>
  </p>

  <div class="close-button-block">
    <a (click)="close()">{{ i18n.close }}</a>
  </div>
</div>
