<h1>{{ title }}</h1>

@if (showControls) {
  <div class="icons-block">
    <div (click)="printSong()" class="icon print-icon" title="{{i18n.printTitle}}">
      <gt-svg-icon icon="printer"></gt-svg-icon>
    </div>
    <div (click)="showKeyboardShortcuts()" class="icon settings-icon" title="{{i18n.showShortcutsTitle}}">
      <gt-svg-icon icon="command-key"></gt-svg-icon>
    </div>
  </div>
}

@if (showCollectionLink === true || (showCollectionLink === 'if-not-primary' && !isPrimaryCollection)) {
  @let primaryCollection = primaryCollection$ | async;
  @if (primaryCollection) {
    <div class="collection-link">
      <a [routerLink]="collectionLink" class="dark-pill" title="{{i18n.goToCollectionTitle}}">{{ primaryCollection.name + ' »' }}</a>
    </div>
  }
}