@if (isFrameVisible) {
  <div class="youtube-video">
    @if (isVideoLoadAllowed) {
      <iframe [ngStyle]="videoCssStyle"
              [src]="'https://www.youtube.com/embed/'+youtubeId | safeResourceUrl"
              [title]="title"
              allowfullscreen="allowfullscreen"
      ></iframe>
    }
  </div>
}